<template>
  <JoszakiCard>
    <div
      class="w-full flex flex-col md:flex-row items-center md:items-start gap-1 md:gap-2 flex-1 md:flex-grow-0"
    >
      <PersonPictureSet
        :class="[
          'rounded-full overflow-hidden w-40 h-40 md:w-32 md:h-32 lg:w-40 lg:h-40',
          'md:row-span-5 md:w-40 md:h-40 md:rounded-md',
        ]"
        :person-id="person.id"
        :alt="person.name"
        @error="handlePictureError"
      />
      <div class="flex flex-col flex-1 gap-1 w-full">
        <div class="flex flex-row md:justify-between justify-center">
          <JoszakiHeading class="!pt-0 !pb-0 md:text-left md:inline">
            {{ person.name }}
          </JoszakiHeading>
          <div class="hidden md:inline-flex flex-row">
            <PersonBadge
              v-for="badge in person.labels"
              :key="badge.name"
              :name="badge.name"
              :description="badge.description"
              class="font-extrabold !text-xs mr-2"
            />
          </div>
        </div>
        <div class="flex max-md:justify-center">
          <PersonRating :person="person" />
        </div>
        <div class="flex md:hidden flex-row justify-center">
          <PersonBadge
            v-for="badge in person.labels"
            :key="badge.name"
            :name="badge.name"
            :description="badge.description"
            class="font-extrabold !text-xs mr-2"
          />
        </div>
        <div
          class="flex items-center justify-center md:justify-start gap-1 !text-sm"
        >
          <span>
            {{ person.winCount }} megbízás ·
            <a href="#ratings" class="underline">
              {{ scoreCount }} értékelés
            </a>
          </span>
        </div>
        <div
          v-if="rankedProfessions?.length"
          class="flex flex-wrap items-center justify-center md:justify-start text-primary !text-sm gap-1"
        >
          <IconComponent pack="fas" icon="briefcase" />
          <span
            v-for="profession in rankedProfessions"
            :key="profession.id"
            class="first-letter:capitalize profession"
          >
            {{ profession.name }}
          </span>
        </div>
        <div
          class="flex flex-wrap items-center justify-center md:justify-start gap-1 !text-sm"
        >
          <template v-if="!city">
            <template v-if="person.goToHouse">
              <template v-if="goToWholeCountry">
                <IconComponent icon="map-marker-alt" />
                {{ $t("personProfile.location.everywhere") }}
              </template>
              <template v-else>
                <i18n path="personProfile.location.goToHouse" :tag="false">
                  <template #city>
                    <IconComponent icon="map-marker-alt" />
                    <b>{{ person.city.name }}</b>
                  </template>
                  <template #km>
                    <b>
                      {{ person.goToHouseKm }}
                    </b>
                  </template>
                </i18n>
              </template>
            </template>
            <template v-else>
              <i18n path="personProfile.location.inCity" :tag="false">
                <template #city>
                  <IconComponent icon="map-marker-alt" />
                  <b>{{ person.city.name }}</b>
                </template>
              </i18n>
            </template>
          </template>
          <template v-else-if="city">
            <IconComponent icon="check" class="text-green-500" />
            <b>
              <span>{{ $t("workThere.text", { cityName: city.name }) }}</span>
            </b>
          </template>
        </div>
        <div
          class="flex items-center justify-center md:justify-start gap-1 text-sm"
        >
          <IconComponent icon="clock" />
          {{ $t("profileCard.lastSeen", { timeAgo: dateDifferenceToNow }) }}
        </div>
      </div>
    </div>
    <div class="hidden md:grid grid-cols-2 min-h-16 gap-4 mt-2">
      <div
        class="bg-error text-white rounded-md flex h-full cursor-pointer"
        @click="onClickRequestTender('sticky')"
      >
        <div class="flex-0 w-16 flex justify-center items-center">
          <IconComponent class="!text-2xl" icon="comments" />
        </div>
        <div class="flex-1 flex flex-col justify-between py-2">
          <div class="font-bold text-xl">
            {{ $t(`requestTender.fromPerson`) }}
          </div>
          <div class="text-xs">
            {{ $t("sticky.oneMinAndFree") }}
          </div>
        </div>
      </div>
      <a
        class="bg-white text-primary hover:text-primary-dark hover:border-primary-dark border-primary border-solid border-2 rounded-md flex h-full cursor-pointer relative"
        :href="$helpers.getPhoneNumberLink(person.phoneNumber)"
        @click="onClickPhoneNumber"
      >
        <div class="flex-0 w-16 flex justify-center items-center">
          <IconComponent class="!text-2xl" icon="phone-alt" />
        </div>
        <div class="flex-1 flex flex-col justify-between py-2">
          <div class="font-bold text-xl">
            {{ $helpers.formatPhoneNumber(person.phoneNumber) }}
          </div>
          <div class="text-xs">
            {{ $t("sticky.callMeControl") }}
          </div>
        </div>
      </a>
      <div class="w-full flex justify-end cursor-pointer col-span-2">
        <b-tooltip
          position="is-top"
          multilined
          type="is-white"
          :label="$t('sticky.phoneTooltip')"
        >
          <b-icon icon="info-circle" /> {{ $t("sticky.phoneHint") }}
        </b-tooltip>
      </div>
    </div>
    <ProfileTabs class="max-w-[90vw]" />
  </JoszakiCard>
</template>

<script>
import { DateTime } from "luxon";
import { mapState } from "pinia";
import PersonBadge from "~/components/_refactored/profile/PersonBadge.vue";
import PersonPictureSet from "~/components/PersonPictureSet.vue";
import { differenceToNow } from "~/helpers/date";
import { useProfessionalStore } from "~/stores/professional";
import PersonRating from "~/components/profile/PersonRating.vue";
import ProfileTabs from "~/components/_refactored/profile/ProfileTabs.vue";

export default {
  components: {
    ProfileTabs,
    PersonRating,
    PersonPictureSet,
    PersonBadge,
  },
  props: {
    person: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      professionalStore: useProfessionalStore(),
    };
  },
  data: () => ({
    showMoreVisible: false,
    memoExpanded: false,
  }),
  computed: {
    ...mapState(useProfessionalStore, {
      city: "city",
    }),
    score() {
      if (this.person.score < 10) {
        return this.person.score.toFixed(1);
      }
      return this.person.score;
    },
    scoreCount() {
      return this.person.scores.filter((x) => !x.deleted).length;
    },
    rankedProfessions() {
      const professions = [...(this.person?.professions || [])];
      // Sort by wincount in descending order
      return professions.sort((a, b) => b.winCount - a.winCount).slice(0, 3);
    },
    goToWholeCountry() {
      return this.person?.goToHouseKm > 500;
    },
    dateDifferenceToNow() {
      if (!this.person?.lastLogin) {
        return null;
      }
      const lastHere = DateTime.fromISO(this.person.lastLogin).diff(
        DateTime.now(),
        "days"
      );

      // if was here in the past 5 years return null
      if (lastHere > 5 * 356) {
        return null;
      }

      return differenceToNow(this, this.person?.lastLogin);
    },
  },
  mounted() {
    if (this.$refs.memo) {
      this.showMoreVisible =
        this.$refs.memo.scrollHeight > this.$refs.memo.clientHeight;
    }
  },
  methods: {
    onClickPhoneNumber() {
      this.$trackers.trackEvent({
        title: "Clicked on Phone Number on Profile",
        data: {
          personId: this.person?.id,
          personSeoName: this.person?.seoName,
        },
      });
      this.$track.customEvent({
        event: "initiate_call",
        ecommerce: { items: [this.person.id] },
      });
    },
    onClickRequestTender(source) {
      this.$emit("requestTender", source);
    },
    handlePictureError() {
      this.professionalStore.pictureError();
    },
  },
};
</script>

<style lang="scss" scoped>
.profession {
  &:not(:last-of-type):after {
    content: "·";
  }
}
</style>
